/* stylelint-disable no-descending-specificity */
@use '@angular/material' as mat;
@use 'material/variables' as variables;

/* stylelint-disable selector-no-qualifying-type */
a,
button {
  &.mat-mdc-button-base {
    border-radius: 10px;
    width: max-content;
    letter-spacing: normal;

    &.mat-mdc-unelevated-button {
      &.mat-primary {
        &:not(:disabled) {
          background-color: var(--wzb-bo-primary);
        }
      }

      &.mat-accent {
        &:not(:disabled) {
          background-color: var(--wzb-bo-blue);
          color: white;
        }
      }

      .mat-icon {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &.mdc-button--outlined {
      height: 2rem;
      font-weight: 500;
      font-size: 0.75rem;
      padding: 0 0.8rem;

      &:not(:disabled) {
        border-color: var(--wzb-bo-blue) !important;
        color: var(--wzb-bo-blue) !important;

        /* stylelint-disable-next-line selector-max-specificity */
        .mat-icon.mat-accent {
          color: var(--wzb-bo-blue);
        }
      }
    }

    &.mat-mdc-icon-button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      max-height: 2.5rem;
      max-width: 2.5rem;

      .mat-icon {
        width: 1rem;
        height: 1rem;
        margin: auto;
      }

      .mat-icon[tall-icon] {
        width: 1.125rem;
        height: 1.125rem;
      }
    }

    .mat-icon {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
