/* stylelint-disable selector-max-type, selector-no-qualifying-type */

@mixin form-with-sections {
  header {
    border-radius: var(--wzb-bo-border-radius) var(--wzb-bo-border-radius) 0 0;
    background-color: white;
    padding: 1.5rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    flex-wrap: nowrap;
    position: sticky;
    top: calc(var(--header-row));
    z-index: 2;
    border-bottom: 1px solid var(--wzb-bo-light-grey);
    > button[type='submit'] {
      margin-left: auto;
    }
  }

  > ul {
    display: grid;
    gap: 1.25rem;
    > :first-child {
      border-radius: 0 0 var(--wzb-bo-border-radius) var(--wzb-bo-border-radius);
    }

    > li {
      border-radius: var(--wzb-bo-border-radius);
      background-color: white;
      padding: 1.5rem;
    }

    fieldset {
      display: grid;
      gap: 1rem;
    }
  }
}
