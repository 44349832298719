@use '@angular/material' as mat;
@use 'material/variables' as variables;

/* stylelint-disable selector-max-specificity */
.mat-checkbox.mat-checkbox--toggle-switch {
  .mat-checkbox-frame,
  .mat-checkbox-checkmark,
  .mat-ripple.mat-checkbox-ripple {
    display: none;
  }

  .mat-checkbox-layout {
    align-items: center;

    .mat-checkbox-label {
      font-weight: 500;
    }

    .mat-checkbox-inner-container {
      width: 3.25rem;
      height: 1.875rem;
      border-radius: 1rem;
      background-color: #aaa;
      transition: background-color 100ms linear;

      .mat-checkbox-background {
        top: 50%;
        transform: translateY(-50%);
        left: 0.625rem;
        width: 0.25rem;
        height: 1.25rem;
        border-radius: 0.5rem;
        background-color: white;
        opacity: 1;
        transition:
          left 100ms linear,
          width 100ms linear,
          border-radius 100ms linear;
      }
    }
  }

  &.mat-checkbox-checked {
    .mat-checkbox-inner-container {
      background-color: mat.get-color-from-palette(variables.$primary);

      .mat-checkbox-background {
        left: 1.75rem;
        width: 1.25rem;
        border-radius: 50%;
      }
    }
  }
}
