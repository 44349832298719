/* stylelint-disable unit-disallowed-list */
/* stylelint-disable-next-line selector-not-notation */
.mat-accordion:not(.wzb-bo-menu):not([multiplesidemenu]) {
  .mat-expansion-panel {
    box-shadow:
      0 3px 1px -2px rgb(0 0 0 / 20%),
      0 2px 2px 0 rgb(0 0 0 / 14%),
      0 1px 5px 0 rgb(0 0 0 / 12%) !important;
  }
}
