/* stylelint-disable selector-max-specificity */

.mdc-menu-surface.mat-mdc-select-panel {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
  border-radius: 1.25rem !important;
  box-shadow: 0 0.5rem 1rem rgb(96 97 112 / 16%);

  .mat-mdc-option {
    padding: 0 1rem;
    margin: 0 0.5rem;
    border-radius: 10px;
    height: auto;
    min-height: 2.25em;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 1.4;

    .mdc-list-item__primary-text {
      color: var(--wzb-bo-text-black);
    }

    &.mat-mdc-option:active {
      font-weight: 500;
      background-color: rgb(0 0 0 / 12%) !important;

      .mdc-list-item__primary-text,
      .mat-option-text {
        color: var(--wzb-bo-primary) !important;
      }
    }

    &.mat-mdc-option:hover {
      font-weight: 500;
      background-color: var(--wzb-bo-light-purple);

      .mdc-list-item__primary-text,
      .mat-option-text {
        color: var(--wzb-bo-purple);
      }
    }
  }
}
