@use 'sass:list';

:root {
  --wzb-bo-purple: #4b45b2;
  --wzb-bo-light-purple: rgb(225 224 249);
  --wzb-bo-blue: #005ff6;
  --wzb-bo-grey: #6d7079;
  --wzb-bo-light-grey: #ebe9f0;
  --wzb-bo-text-grey: #4d515c;
  --wzb-bo-text-black: #242936;
  --wzb-bo-title: #222;
  --wzb-bo-text-disable: #666;
  --wzb-bo-pink: #ff7f89;
  --wzb-bo-blue-night: #0f2a47;
  --wzb-bo-blue-dodger: #3e7bfa;
  --wzb-bo-blue-turquoise: #00d2e1;
  --wzb-bo-orange-sun: #ffa915;
  --wzb-bo-magenta: #b952e0;
  --wzb-bo-yellow: #fc0;
  --wzb-bo-error-background: #fdded2;
  --wzb-bo-yellow-light: #fff3de;
  --wzb-bo-blue-light: #f6f5ff;
  --wzb-bo-blue-light-background: #efeeff;
  --wzb-bo-invalid-red: #ce3f43;
  --wzb-bo-success-green: #21d3a2;
  --wzb-bo-warn-orange: #f5a623;
  --wzb-bo-info-blue: #005ff7;
  --wzb-bo-validate: #055a42;
  --wzb-bo-background: #f4f3f2;
  --wzb-bo-disabled: rgb(15 42 71 / 10%);
  --wzb-bo-border-color: rgb(15 42 71 / 10%);
  --wzb-bo-primary: var(--wzb-bo-purple);
  --wzb-bo-accent: var(--wzb-bo-blue);
  --wzb-bo-warn-background: #ffece4;
  --wzb-bo-warn-border: #fdded2;
  --wzb-bo-form-field-border: #dcd9e3;
  --shadow-one: inset 0 0.0313rem 0.25rem 0 rgba(96 97 112 / 32%);
  --shadow-two: 0 0.0313rem 0.125rem 0 rgba(96 97 112 / 16%), 0 0 0.0625rem 0 rgba(40 41 61 / 08%);
  --shadow-three: 0 0.125rem 0.25rem 0 rgba(96 97 112 / 16%), 0 0 0.0625rem 0 rgba(40 41 61 / 04%);
  --shadow-four: 0 0.25rem 0.5rem 0 rgba(96 97 112 / 16%), 0 0 0.125rem 0 rgba(40 41 61 / 04%);
  --shadow-five: 0 0.5rem 1rem 0 rgba(96 97 112 / 16%), 0 0.125rem 0.25rem 0 rgba(40 41 61 / 04%);
  --shadow-six: 0 1rem 1.5rem 0 rgba(96 97 112 / 16%), 0 0.125rem 0.5rem 0 rgba(40 41 61 / 04%);
  --shadow-seven: 0 1.25rem 2rem 0 rgba(96 97 112 / 24%), 0 0.125rem 0.5rem 0 rgba(40 41 61 / 08%);
  --input-explain-bloc-background-color: #fcf9f0;
  --input-explain-bloc-border-color: #f5a623;
}

// The key is the deprecated variable, note the missing $. The value is the newly suggested one.
$deprecated: ('shadow-one', 'shadow-two', 'shadow-three', 'shadow-four', 'shadow-five', 'shadow-six', 'shadow-seven');

@each $variable in $deprecated {
  @if variable-exists(list.nth($variable, 1)) {
    @warn "The scss variable $#{$variable} is deprecated. Please create and use a new pure CSS variable instead.";
  }
}
