.mat-mdc-chip.mat-mdc-standard-chip {
  background-color: var(--wzb-bo-blue) !important;
  border-radius: 5px;
  height: fit-content;
  padding: 0.3125rem 0;

  .mdc-evolution-chip__cell--trailing {
    opacity: 1 !important;
  }

  &:not(.mdc-evolution-chip--disabled) {
    .mdc-evolution-chip__icon--trailing,
    .mdc-evolution-chip__text-label {
      color: white;
    }
  }

  .mat-icon {
    height: auto;
  }
}

app-dropdown-tags .mat-mdc-text-field-wrapper.mdc-text-field {
  padding-right: 1rem;
}
