// stylelint-disable selector-max-specificity, selector-no-qualifying-type, selector-type-no-unknown, no-descending-specificity, selector-max-type, max-nesting-depth
@use '@wizbii-components/bo-styles/src/colors/bo-colors.scss' as colors;

:is(app-nav-tabs, mat-tab-group[multipleSideMenu]) {
  .mat-mdc-tab-header {
    border-bottom: 2px solid var(--wzb-bo-border-color);
    width: fit-content;

    .mdc-tab-indicator {
      height: 0.125rem;
      bottom: 0;
      left: unset;
      top: unset;
      width: calc(100% - 2 * 0.75rem);
    }

    .mdc-tab-indicator__content--underline {
      --mdc-tab-indicator-active-indicator-color: var(--wzb-bo-purple);

      border-radius: 2px;
    }

    .mat-mdc-tab-labels {
      .mat-mdc-tab {
        flex-grow: unset !important;
      }

      .mdc-tab {
        padding: 0;
        min-width: 0;
        height: auto;
      }
    }

    .mat-mdc-tab[aria-selected='true'] {
      .mdc-tab__text-label {
        color: var(--wzb-bo-purple) !important;
      }
    }

    .mdc-tab__text-label {
      line-height: 1.3;
      font-size: 0.8125rem;
      font-weight: 500;
      font-style: normal;
      letter-spacing: normal;
      color: var(--wzb-bo-text-grey);
      padding: 0 0.75rem;
    }

    .mdc-tab__content {
      margin-bottom: 0.25rem;
      margin-top: 0.25rem;
    }
  }

  .mdc-tab[aria-posinset='1'] {
    .mdc-tab__text-label {
      padding-left: 0;
    }

    .mdc-tab-indicator {
      width: calc(100% - 0.75rem);
      left: 0;
    }
  }
}
