@use '@angular/material' as mat;

$md-primary: (
  50: #e9e9f6,
  100: #c9c7e8,
  200: #a5a2d9,
  300: #817dc9,
  400: #6661be,
  500: #4b45b2,
  600: #443eab,
  700: #3b36a2,
  800: #332e99,
  900: #231f8a,
  A100: #c7c5ff,
  A200: #9692ff,
  A400: #655fff,
  A700: #4d46ff,
  contrast: (
    50: #000,
    100: #000,
    200: #000,
    300: #000,
    400: #fff,
    500: #fff,
    600: #fff,
    700: #fff,
    800: #fff,
    900: #fff,
    A100: #000,
    A200: #000,
    A400: #fff,
    A700: #fff,
  ),
);
$md-accent: (
  50: #e0ecff,
  100: #b3d0ff,
  200: #80b1ff,
  300: #4d91ff,
  400: #267aff,
  500: #0062ff,
  600: #005aff,
  700: #0050ff,
  800: #0046ff,
  900: #0034ff,
  A100: #fff,
  A200: #f2f4ff,
  A400: #bfc8ff,
  A700: #a6b3ff,
  contrast: (
    50: #000,
    100: #000,
    200: #000,
    300: #000,
    400: #fff,
    500: #fff,
    600: #fff,
    700: #fff,
    800: #fff,
    900: #fff,
    A100: #000,
    A200: #000,
    A400: #000,
    A700: #000,
  ),
);

/* For use in src/lib/core/theming/_palette.scss */
$md-warn: (
  50: #ffebeb,
  100: #fecece,
  200: #feadad,
  300: #fd8c8c,
  400: #fc7373,
  500: #fc5a5a,
  600: #fc5252,
  700: #fb4848,
  800: #fb3f3f,
  900: #fa2e2e,
  A100: #fff,
  A200: #fff,
  A400: #ffd4d4,
  A700: #fbb,
  contrast: (
    50: #000,
    100: #000,
    200: #000,
    300: #000,
    400: #000,
    500: #000,
    600: #000,
    700: #fff,
    800: #fff,
    900: #fff,
    A100: #000,
    A200: #000,
    A400: #000,
    A700: #000,
  ),
);

/* For use in src/lib/core/theming/_palette.scss */
$md-success: (
  50: #e4faf4,
  100: #bcf2e3,
  200: #90e9d1,
  300: #64e0be,
  400: #42dab0,
  500: #21d3a2,
  600: #1dce9a,
  700: #18c890,
  800: #14c286,
  900: #0bb775,
  A100: #e4fff3,
  A200: #b1ffde,
  A400: #7effc8,
  A700: #64ffbd,
  contrast: (
    50: #000,
    100: #000,
    200: #000,
    300: #000,
    400: #000,
    500: #000,
    600: #000,
    700: #000,
    800: #000,
    900: #fff,
    A100: #000,
    A200: #000,
    A400: #000,
    A700: #000,
  ),
);
$primary: mat.define-palette($md-primary);
$accent: mat.define-palette($md-accent);
$warn: mat.define-palette($md-warn);
$success: mat.define-palette($md-success);
