:root {
  .filepicker-contrast {
    .filepicker {
      background-color: #222333;
    }
  }

  .filepicker-error {
    .filepicker {
      border: 1px solid var(--wzb-bo-invalid-red) !important;
    }
  }
}
