.mat-mdc-tab-nav-bar {
  .mdc-tab {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }

  .mdc-tab__content {
    margin-bottom: 0.25rem;
    margin-top: 0.25rem;
  }

  .mat-mdc-tab-link:not(.mdc-tab--stacked) {
    --mat-tab-header-label-text-size: 0.8125rem;
    --mdc-secondary-navigation-tab-container-height: 2rem;
    --mat-tab-header-label-text-letter-spacing: normal;

    // stylelint-disable-next-line selector-max-specificity
    &.mdc-tab--active .mdc-tab__text-label {
      color: var(--wzb-bo-primary);
    }
  }
}

:root {
  nav {
    .mat-mdc-tab-link-container {
      width: fit-content;
      flex-grow: 0;
    }
  }
}
